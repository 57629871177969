import "./EmptyTodo.css";

function EmpyTodo(){
    return(
        <div className="containerEmptyTodo">
            <h3>No se encontraron TODOS</h3>
        </div>
    )
}

export { EmpyTodo }
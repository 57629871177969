import "./Loading.css";


function Loading(){
    return(
        <div className="containerSpiner">
            <div className="LoadingSpiner">
            </div>
        </div>
    )
}

export { Loading };